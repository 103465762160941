import { NgModule } from '@angular/core';
import { ImportsPage } from './imports.page';
import { ImportsDataTableComponent } from './components/imports-data-table/imports-data-table.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkMenuModule } from '@angular/cdk/menu';
import { DialogModule } from '@angular/cdk/dialog';
import { OverlayModule } from '@angular/cdk/overlay';
import { MaterialModule } from 'src/app/core/modules/material/material.module';
import { DataTableV2Module } from 'src/app/shared/components/design-system/data-table-v2/data-table-v2.module';
import { TagsModule } from 'src/app/shared/components/design-system/tags/tags.module';
import { TooltipModule } from 'src/app/shared/components/design-system/tooltip/tooltip.module';
import { DialogContainerModule } from 'src/app/shared/components/dialog-container/dialog-container.module';
import { ImportsRoutingModule } from './imports-routing.module';
import { ProgressSpinnerModule } from 'src/app/shared/components/design-system/progress-spinner/progress-spinner.module';
import { ProgressBarModule } from 'src/app/shared/components/design-system/progress-bar/progress-bar.module';
import { ImportDetailsComponent } from './components/import-details/import-details.component';
import { ImportDetailsDataTableComponent } from './components/import-details/components/import-details-data-table/import-details-data-table.component';
import { ButtonModule } from 'src/app/shared/components/design-system/button/button.module';
import { NgxsModule } from '@ngxs/store';
import { ImportsState } from './components/imports-data-table/state/imports.state';
import { ImportsDataTableState } from './components/import-details/components/import-details-data-table/state/import-details-data-table.state';
import { SentenceCasePipe } from 'src/app/shared/pipes/sentence-case.pipe';
import { CustomDecimalPipe } from 'src/app/shared/pipes/custom-decimal.pipe';

@NgModule({
  declarations: [
    ImportsPage,
    ImportsDataTableComponent,
    ImportDetailsComponent,
    ImportDetailsDataTableComponent,
  ],
  imports: [
    ImportsRoutingModule,
    CommonModule,
    SharedModule,
    CdkTableModule,
    CdkMenuModule,
    TooltipModule,
    OverlayModule,
    MaterialModule,
    TagsModule,
    DataTableV2Module,
    DialogContainerModule,
    DialogModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    ButtonModule,
    SentenceCasePipe,
    CustomDecimalPipe,
    NgxsModule.forFeature([ImportsState, ImportsDataTableState]),
  ],
})
export class ImportsModule {}
